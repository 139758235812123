import React, { useState, useEffect } from 'react';
import Controls from './Controls';
import Preview from './Preview';
import OrderFormModal from './OrderFormModal';
import { getColors, getValidGraphicColors } from './utils';
import { stockData } from '../data/stockData';

const ProductList = () => {
  const [selectedProduct, setSelectedProduct] = useState('hoodies'); // Default to hoodies
  const [selectedColor, setSelectedColor] = useState('black'); // Default color
  const [selectedSize, setSelectedSize] = useState('M'); // Default size
  const [selectedGraphic, setSelectedGraphic] = useState('Badgastein'); // Default graphic
  const [selectedGraphicColor, setSelectedGraphicColor] = useState('black'); // Default graphic color
  const [showModal, setShowModal] = useState(false); // Modal state

  const colors = getColors(selectedProduct);

  // Get available sizes for the current product and color
  const availableSizes = stockData[selectedProduct]?.[selectedColor] || {};

  // Ensure the selected color is valid when switching product types
  useEffect(() => {
    if (!colors.includes(selectedColor)) {
      setSelectedColor(colors[0]); // Fallback to the first available color
    }
  }, [selectedProduct, colors, selectedColor]);

  const validGraphicColors = getValidGraphicColors(selectedGraphic);

  // Ensure the selected graphic color is valid
  useEffect(() => {
    if (!validGraphicColors.includes(selectedGraphicColor)) {
      setSelectedGraphicColor(validGraphicColors[0]); // Fallback to the first valid color
    }
  }, [selectedGraphic, validGraphicColors, selectedGraphicColor]);

  return (
    <div className="main-container">
      <Preview
        productType={selectedProduct}
        productColor={selectedColor}
        graphicType={selectedGraphic}
        graphicColor={selectedGraphicColor}
      />
      <button
        className="open-order-button"
        onClick={() => setShowModal(true)}
      >
        ORDER NOW
      </button>
      <Controls
        productType={selectedProduct}
        setProductType={setSelectedProduct}
        productColor={selectedColor}
        setProductColor={setSelectedColor}
        productSize={selectedSize}
        setProductSize={setSelectedSize}
        graphicType={selectedGraphic}
        setGraphicType={setSelectedGraphic}
        graphicColor={selectedGraphicColor}
        setGraphicColor={setSelectedGraphicColor}
        colors={colors}
        availableSizes={availableSizes} // Pass available sizes to Controls
      />
      <div className="graphic-controls-container">
        <h2>Graphic</h2>
        <div className="graphic-controls">
          {[...new Set([
            'Badgastein', 'Compass', 'Dweller',
            'Paradise', 'Schareck', 'Spirit', 'Sporty', 'Waterfall',
            'Authentic', 'Deer', 'Grizzly', 'Pinetree', 'Deer2',
            'Pow', 'Solid', 'Summit', 'Visions',
          ])].map((graphic) => (
            <button
              key={graphic}
              className={`graphic-button ${selectedGraphic === graphic ? 'active' : ''}`}
              onClick={() => {
                setSelectedGraphic(graphic);
                const validColors = getValidGraphicColors(graphic);
                if (!validColors.includes(selectedGraphicColor)) {
                  setSelectedGraphicColor(validColors[0]);
                }
              }}
            >
              {graphic}
            </button>
          ))}
        </div>
        <h2>Graphic Color</h2>
        <div className="graphic-color-controls">
          {validGraphicColors.map((color) => (
            <button
              key={color}
              className={`graphic-color-button ${selectedGraphicColor === color ? 'active' : ''}`}
              onClick={() => setSelectedGraphicColor(color)}
            >
              {color.charAt(0).toUpperCase() + color.slice(1)}
            </button>
          ))}
        </div>
      </div>
      {showModal && (
        <OrderFormModal
          closeModal={() => setShowModal(false)}
          selectedProduct={selectedProduct}
          selectedColor={selectedColor}
          selectedSize={selectedSize}
          selectedGraphic={selectedGraphic}
          selectedGraphicColor={selectedGraphicColor}
        />
      )}
    </div>
  );
};

export default ProductList;
